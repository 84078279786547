#modal-frame {


    .modal {
        position: fixed;
        overflow: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.65);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5em 1em;
        z-index: 999999;
        box-sizing: border-box;
    }




    .modal.modal-fade {
        animation: fade-in 1s 1 linear;
        animation-fill-mode: forwards;
        opacity: 0;
    }

    .modal>.modal-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .modal-body {
        z-index: 2;

        margin: 0 auto;
        width: 100%;
        background: radial-gradient(white, $secondary);
        // background-color: $background-color;
        overflow-x: hidden;
        overflow-y: hidden;
        max-height: 100%;
        color: #c3c0c0;
        display: flex;
        flex-direction: column;
        justify-items: center;

        &.modal-window {
            position: relative;
            border: 2px solid lightgray;
            border-radius: 10px;
            padding: 15px 20px;
            max-width: 35rem;
            width: 80%;
            height: 61vh;

            @media only screen and (max-width: 480px) {
                height: 55vh;

                &.splashscreen {
                    height: 30vh !important;
                }
            }

            .modal-close {
                // color: #5e5e5e;
                color: #c3c0c0;
                cursor: pointer;
                // font-size: 1.25em;
                // padding: 7px;
                // background: rgba(255, 255, 255, 0.749);
                // border: 1px solid #c3c0c0;
                // border-radius: 50%;
                // width: 32px;
                // height: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 999999;
                box-sizing: border-box;
                display: inline-block;
                text-align: center;
                align-self: flex-end;

                & .modal-cross {
                    position: relative;
                    top: -25px;
                }

                & svg {
                    fill: currentColor;
                }
            }
        }

        &.modal-fullscreen {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .modal-close {
                // color: #5e5e5e;
                color: #c3c0c0;
                cursor: pointer;
                font-size: 1.25em;
                padding: 7px;
                // background: rgba(255, 255, 255, 0.749);
                // border: 1px solid #c3c0c0;
                // border-radius: 50%;
                width: 32px;
                height: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 999999;
                box-sizing: border-box;
                display: inline-block;
                text-align: center;
                align-self: flex-end;
                // margin: -5px -10px;

                & svg {
                    fill: currentColor;
                }
            }
        }

        &.body-color1 {
            background: $third;
        }

        &.body-color2 {
            background: $primary;
        }

        &.body-color3 {
            background: $third;
        }

        &.body-color4 {
            // background: var(--linear-gradient-important);
            background: $primary-gradient;
        }


    }

    .modal-body>.modal-close:hover {
        // background: rgba(255, 255, 255, 0.989);
    }

    .modal-content {
        margin-top: 15px;

    }


    .popin-header {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid #dddddd;

        .popin-title {
            text-align: center;
            width: 100%;
            padding: 5px;
        }

        .alert {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;

            & svg {
                fill: currentColor;
                width: 32px;
                height: 33px;
            }
        }

    }


    .popin-center {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .popin-line {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &.hrlike {
            border-bottom: 2px solid #dddddd;
            width: 80%
        }
    }

    .popin-email {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 3rem;

        textarea {
            margin-top: 20px;
        }
    }

    .greyed {
        color: #cccccc !important;
        font-weight: bolder
    }

    .darked {
        color: black !important;
        font-weight: bolder
    }

    @keyframes fade-in {
        0% {
            animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }


}