.signin {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .signin-line {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        padding-bottom: 1rem;

        .signin-line-left {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: center;
            font-size: .9rem;
        }

        .signin-line-right {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-weight: bold;
            font-size: .7rem;
        }

    }
}