.frame {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.layout {
  max-width: 1200px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 65px;
  background: $background-color;
  width: 100%;
  height: 100%;


  .content-frame {

    max-width: 600px;
  }

  // .page {
  //   width: 100%;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  // }

  // .card {
  //   width: 85% !important;
  //   padding: 7px;
  // }
}

.innerMediumWidth,
.innerSmallWidth,
.innerFormWidth,
.innerLargeWidth {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 1200px) {
    padding: 0;
  }
}

.innerMediumWidth {
  max-width: 1240px;
}

.innerFormWidth {
  max-width: 920px;
}

.innerSmallWidth {
  max-width: 1040px;
}

.innerLargeWidth {
  max-width: 1440px;
}

.innerBottomMenu {
  justify-content: space-around;
}

.fullGrid {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
}

// .fullGrid > * {
//   flex: 100%;
// }

.halfGrid>* {
  flex: 50%;
  max-width: 50%;
}

.fullLine {
  // background-color: red;
  flex-grow: 1;
  width: 100%;

}

.centerLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.leftPart {
  width: 80%;
  z-index: 1;
}

.rightPart {
  // width: 20%;
  z-index: 3;
}

.revGrid {
  flex-direction: row-reverse;
}

/** Définition des grilles
    * --------------------------------------------- */
.grid10 {
  flex: 10%;
  max-width: 10%;
}

.grid20 {
  flex: 20%;
  max-width: 20%;
}

.grid25 {
  flex: 25%;
  max-width: 25%;
}

.grid30 {
  flex: 30%;
  max-width: 30%;
}

.grid33 {
  flex: calc(100% / 3);
  max-width: calc(100% / 3);
}

.grid35 {
  flex: 35%;
  max-width: 35%;
}

.grid40 {
  flex: 40%;
  max-width: 40%;
}

.grid45 {
  flex: 45%;
  max-width: 45%;
}

.grid60 {
  flex: 60%;
  max-width: 60%;
}

.grid66 {
  flex: calc(100% * 2 / 3);
  max-width: calc(100% * 2 / 3);
}

.grid70 {
  flex: 70%;
  max-width: 70%;
}

.grid75 {
  flex: 75%;
  max-width: 75%;
}

.grid80 {
  flex: 80%;
  max-width: 80%;
}

.grid90 {
  flex: 90%;
  max-width: 90%;
}

.grid100 {
  flex: 100%;
  max-width: 100%;
}

.extra-pad-left {
  padding-left: 1rem !important;
}

.small-top {
  // display: none;
  color: $fourth;
  background-color: #212121;
  width: 100%;
  position: fixed;
  top: 0;
  text-align: center;
  font-size: .5rem;
  font-weight: 600;
  margin-top: -.5rem;
  padding-top: .5rem;
}