.user-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .user-frame {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .box-label {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: center;
            color: #5a5959;
            text-decoration: none;
            font-weight: 700;
            font-size: .65rem;
        }

        .box-content {
            width: 50%;
            text-decoration: none;
            font-weight: 700;
            font-size: .65rem;
            color: $primary;
        }

        .box-dash {}

    }
}

.code-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .code-frame {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .code-line {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .code-input {
                margin-right: .3rem;
                text-align: center;
                font-size: 1.1rem;
                font-weight: 600;
                font-family: "Courier New";
                color: rgb(186, 186, 186) !important;

                border: 2px solid rgb(194, 194, 194);
                border-radius: 4px;

                &::placeholder {
                    color: rgb(255, 192, 192);
                }



            }
        }
    }
}