.toggle {

    padding-top: 3px;

    .toggle-icon {
        transform: rotate(90deg);
        margin: 12px 0 0 -8px;
    }


}