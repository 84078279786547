.inputbox {
    .focus-frame {
        .input-frame {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .input-box {
                width: 96%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .input-container {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 2px 0 2px 1px;
                    margin-top: -2px;
                    margin-right: 2px;
                }

                .icon-touched {
                    width: fit-content;
                    height: 18px;
                    margin-left: 2px;

                    >svg {
                        padding-left: 1px;
                    }
                }

                .icon-reset {
                    border-radius: 50%;
                    background: red;
                    width: fit-content;
                    height: 18px;
                    padding-bottom: 1px;
                    margin-left: 2px;
                    margin-top: -3px;

                    >svg {
                        padding-left: 1px;
                        padding-top: 1px;
                    }
                }
            }

            .input-area {
                width: 96%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                .input-container {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 2px 2px 2px 1px;
                    margin-top: -2px;

                    textarea {
                        resize: none !important;

                        overflow: hidden;

                        &.input-textarea {
                            padding-left: 3px;
                            width: 100%;
                        }

                    }
                }

                .icon-col {
                    width: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;

                    .icon-touched {
                        width: fit-content;
                        height: 18px;
                        margin-left: 2px;
                        margin-right: 2px;

                        >svg {
                            padding-left: 1px;
                        }
                    }

                    .icon-reset {
                        border-radius: 50%;
                        background: red;
                        width: fit-content;
                        height: 18px;
                        padding-bottom: 1px;
                        margin-left: 2px;

                        >svg {
                            padding-left: 1px;
                            padding-top: 1px;
                        }
                    }

                }


            }




        }
    }
}