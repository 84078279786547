.dialogbox {

    .dialog-frame {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .dialog-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .dialog-line {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .title-image {
                    border-radius: 50%;


                    img {
                        margin-bottom: -4px;
                        border: 2px groove $primary;
                        border-radius: 50%;
                    }
                }

                .title-brand {
                    font-family: 'Bigelow Rules';
                    font-size: 2.4rem;
                    font-weight: 800;
                    color: $primary;
                    padding-left: 1rem;
                }

                .title {
                    color: darkslategray;
                    font-family: "Raleway";
                    font-size: 1.4rem;
                    font-weight: 700;
                    padding-bottom: 1rem;
                    text-align: center;
                }

                .sentence {
                    width: 80%;
                    color: $primary;
                    font-size: 1.1rem;
                    padding: 0 1rem 1rem 1rem;
                    font-weight: 600;
                    text-align: center;
                }

                .splitbar {
                    width: 80%;
                    border-bottom: 1px solid rgb(214, 214, 214);
                    margin-bottom: 10px;
                }

            }
        }



    }

    .bottom-line {
        padding-top: 0.5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .bottom-text {
            font-size: 0.9rem;
            color: blue;
            text-decoration: underline;

        }
    }
}