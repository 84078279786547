$rt-namespace: 'Toastify';
$rt-mobile: 'only screen and (max-width : 480px)' !default;

:root {
  // --toastify-color-light: #fff;
  // --toastify-color-dark: #121212;
  --toastify-color-light: #{$secondary};
  --toastify-color-dark: #{$primary};

  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #fff;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #{$primary};
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: #{$primary-font};
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  --toastify-color-progress-light: linear-gradient(to right,
    #{$primary},
    #{$secondary});
  // Used when no type is provided
  --toastify-color-progress-dark:#{$secondary};
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  // --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-error: #{$third-gradient};
}