.btn-menu {
    border-radius: $border-radius;
    color: white !important;
    font-weight: 500;
    font-size: 14px;
    min-width: 100px;
    max-width: 100%;
    text-align: center;
    background: $primary;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    .barrette {
        width: 7px;
        height: 64%;
        // background-color: white;
        background-color: $secondary;
        border-radius: 4px;
        margin-left: 8px;
        margin-right: 1rem;
    }
}

.btn-save-input {
    max-width: 10rem;
}

.btn {
    border-radius: 16px;
    color: white !important;
    padding: 11px 22px;
    font-weight: 500;
    font-size: .9rem;
    min-width: 100px;
    max-width: 100%;
    text-align: center;
}

.btn-primary {
    // background: $primary-gradient;
    background: $fourth-gradient;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

}

.btn-primary-ligther {
    background: $secondary;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
}

.btn-second {
    color: $secondary  !important;
    background: $primary;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
}

.btn-validation {
    background: $fourth-gradient;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
}

.btn-cancel {
    font-weight: 600;
    background: white;
    color: $primary  !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
}


.btn-modal {
    width: 8rem;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.txtbutton {
    padding-bottom: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cancel-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 10rem;
}

.link-back {
    position: absolute;
    top: 30px;
    left: 30px;

    svg {
        fill: black;
    }
}

.btn-rounded {
    padding: 6px 10px;

    &.rounded-horizontal {
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.btn-back {
    z-index: 9;
    width: 100%;
    position: relative;
    padding-left: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    overflow: visible;
    cursor: pointer;


    &.dark-bg {
        svg {
            fill: white;
        }
    }

    &.light-bg {
        svg {
            fill: black;
        }
    }

}

.back-arrow-container {
    cursor: pointer;
}

.btn-txt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    .rotate-90 {
        transform: rotate(-90deg);
    }

    .rotate-180 {
        transform: rotate(-90deg);
    }
}

.btn-link {
    font-size: .8rem;
    color: blue;
    text-decoration: underline;
    text-align: center;
}

.btn-test {
    width: 10px;
    height: 10px;
    font-size: .5rem;
    background-color: yellow;
    color: black;
    text-align: center;
    border-radius: 2px;
}

.btn-frame {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn-categ-frame {
    position: relative;
    top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;

    .btn-categ {
        border-radius: 16px;
        padding: 2px 9px;
        margin: 0 4px 10px 4px;
        font-size: .9rem;
        width: fit-content;
        text-align: center;
        font-family: 'Raleway';
        // font-weight: 700;
        background: white;
        color: $primary  !important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

        &.active {
            color: white !important;
            font-weight: bold;
            background: $fourth;
        }

        &:hover {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }

    }


}

div.disabled {
    pointer-events: none;
    opacity: 0.5;
    background: #CCC;
}

div.enabled {
    pointer-events: auto;
    opacity: 0.5;
    background: #CCC;

}