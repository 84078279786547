.focus-frame {
    margin-top: -3px;
    z-index: 12;

    .inputdays-frame {
        width: 3rem;
        height: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-top: -2px;


        .inputdays-box {
            width: 3rem;
            height: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .inputdays-container {
                width: 3rem;
                height: 1rem;


                &:focus {
                    border: 2px solid $primary;

                }

                input {
                    position: static;
                    width: 3rem;
                    padding-top: 2px;
                    border: 1px solid #d0d0d0;
                }

            }
        }
    }
}