.splashscreen {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .logo-box {
        opacity: 0;
        font-family: 'Bigelow Rules';
        font-size: 2.5rem;
        height: 50px;
        color: black;
        background: transparent;
        padding: 0px 12px 0 16px;
        font-weight: bold;
        letter-spacing: 1px;
        text-shadow: 1px 1px 0px $third;
    }

    .logo-text {
        color: black;
        font-size: .9rem;

    }

    .splitbar {
        width: 80%;
        border-bottom: 1px solid rgb(214, 214, 214);
        margin-bottom: 10px;
    }

    .version {
        color: rgb(75, 75, 75);
        font-size: .7rem;
    }
}