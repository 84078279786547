.profile-row {
    width: 100%;
    padding: .4rem .3rem .3rem .3rem;
    margin-bottom: .3rem;

    background-color: $fourth  !important;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0) 80.71%);

    &.self-class {
        // background-color: #d0d0d0;
        // background: linear-gradient(217deg, rgba(255, 255, 255, .8), rgba(255, 0, 0, 0) 70.71%),
        //     linear-gradient(127deg, rgba(255, 177, 174, .8), rgba(0, 255, 0, 0) 70.71%),
        //     linear-gradient(336deg, rgba(248, 177, 174, .8), rgba(0, 0, 255, 0) 70.71%);
        background-color: white !important;
        background:
            linear-gradient(-90deg, rgba(160, 132, 220, .8), rgba(0, 0, 255, 0) 70.71%),
            linear-gradient(0deg, rgba(100, 92, 187, .8), rgba(0, 255, 0, 0) 70.71%);
    }

    .profile-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .profile-line {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            .profile-photo {
                margin-right: .7rem;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 32px;

                .miniphoto-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    width: 32px;
                    height: 32px;

                    .photo {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: baseline;
                        height: 32px;

                        img {
                            aspect-ratio: 1/1;
                            border: 1px solid lightgray;
                            background-color: transparent;
                            border-radius: 50%;
                            -moz-border-radius: 50%;
                            -webkit-border-radius: 50%;

                            &.self-class {
                                border: 1px groove $third;
                            }
                        }
                    }
                }
            }

            .profile-pseudo {
                &.self-class {
                    color: $primary;
                    font-weight: 700;
                }
            }
        }



    }


}