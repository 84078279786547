.smartworks-title {
    color: $primary;
    font-family: "Raleway";
    font-size: 1.2rem;
    font-weight: 700;
    padding-bottom: 1rem;
    text-align: center;
    z-index: 9;
    width: 30%;
    margin-left: -70%;
    line-height: 1.1rem;
}

.smartworks {
    width: 128px;
    height: 9rem;
    // opacity: .6;
    z-index: 9;
}

.dialog-mobile-line {
    width: 75% !important;
}

.dialog-mobile-sentence {
    font-size: .9rem !important;
}

.qrcode-box {
    .qrcode-mobile {
        margin-top: -2rem;
        background: transparent;

        .qrcode-profile {
            border: 2px ridge $third;
            border-radius: $border-radius;
            background-color: ghostwhite;
        }
    }
}

.mobile-only {
    height: 100vh;
    overflow: hidden;
    position: relative;

    .dialogbox {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &::-webkit-scrollbar {
            all: initial !important;
            width: 0px !important;
            background: transparent !important;
        }


        overflow-y: scroll;
        position: absolute;
        // height:70%;

        .dialog-frame {
            .dialog-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 1.4rem;
                margin-bottom: 2rem;
                border: 1px solid #d0d0d0;
                border-radius: $border-radius;
                background-color: $fourth;

                .dialog-mobile-sentence {
                    .sentence {
                        font-size: .9rem !important;
                        color: $secondary ;
                    }
                }

            }
        }
    }
}



.dialog-mobile-line {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .txtbutton {
        margin: 1rem;
    }
}