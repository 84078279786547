.tch-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 32px;
    max-width: 1200px;
    z-index: 3;
    background: $primary-gradient;

    .bottom-line {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: .6rem;
        font-weight: bold;
        align-items: center;
    }

    .footer-left {
        width: 25%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: white;
        letter-spacing: .07em;
        margin: 0;
        padding: 0;


        .connection {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: left;
            font-size: .7rem;
            padding-right: 5px;

            .role-frame {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding-left: 5px;

                .role {
                    font-style: oblique;
                    font-family: 'Raleway';
                    font-weight: 700;
                    // padding-top: -2px;
                    margin-left: -12px;
                    margin-top: -5px;
                    z-index: 1;
                }




                .role-line {

                    z-index: 2;
                    // display: flex;
                    // flex-direction: row;

                }
            }
        }
    }

    .footer-middle-frame {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 36%;

        .footer-middle {
            margin: 0;
            padding: 0;
            color: $secondary  !important;
            font-size: .7rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            letter-spacing: normal;

            .footer-middle-col {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .copyright {
                    padding-top: 2px;
                    color: lightgray;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }


                .number-version {
                    font-size: .6rem;
                    color: snow;
                }

                .footer-brand {
                    background: -webkit-linear-gradient(45deg, rgba(256, 256, 256, 1), $secondary);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-align: center;
                }
            }
        }
    }

    .footer-right {
        width: 25%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: snow;
        font-size: .6rem;

        padding-right: .3rem;

        .online-icon {
            padding-top: 1px;
            padding-left: .4rem;
            padding-right: 3px;
        }

        .date-time {
            min-width: 4rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: right;

        }
    }

}