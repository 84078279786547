.icon-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;

    & svg {
        transform: rotateY(180deg);
    }
}

.icon-system {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
    align-items: flex-start;
    height: 40px;
    min-width: 40px;
    cursor: pointer;

    .right-icon {
        margin-right: -20px;
        position: relative;
        top: 10px;
    }

    .badge {
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        overflow: visible;
        pointer-events: none;
        // background-color: red;
        background: transparent;

        position: relative;
        top: 4px;
        left: -4px;
        padding-bottom: 4px;
        opacity: 1;

        &.nobadge {
            opacity: 0;
        }

        .nfc-badge {
            font: .7rem;
            text-align: center;
            position: relative;
            top: 1px;
            // font-family: 'Arial';
            // font-size: small;
            transform: rotate(90deg);
        }


    }



    svg {

        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        filter: drop-shadow(0px 1px 3px rgb(0 0 0 / 0.4));
    }

    #refresh {
        // border: 1 px solid black;


    }

}