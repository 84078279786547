.page-col {
    position: fixed;
    top: 50px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // padding: 1.5rem 2rem;
    width: 100%;
    height: 100%;

    &.screen-top {
        top: 0;
    }

    &.screen-normal {
        top: 4rem;
        padding-top: 1rem;
    }

    .page-row {
        width: 100%;
        max-width: 600px;
        min-width: 300px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        height: 100%;

        .page-frame {
            position: relative;
            // right: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 96%;
            background: linear-gradient($secondary, $primary);
            border-radius: 0px 0px 18px 18px;
            padding: 2px 0px 0px 0px;

            @supports (-webkit-touch-callout: none) {
                /* CSS specific to iOS devices */
                height: calc(72vh - 60px);
                // height: calc(100vh - 60px);
            }

            @supports not (-webkit-touch-callout: none) {
                /* CSS for other than iOS devices */
                height: calc(100% - 60px);
            }

            .page-line {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: .3rem;
            }

            .page-end {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                // height: 10rem;
                height: calc(100% - 96px);
                flex: 1 1 auto;

                .mySwiper {
                    position: relative;
                    top: 0;
                    // height: calc(100% - 90px);
                    height: 100%;
                    flex: 1 1 auto;
                }
            }

            .page-divider {
                z-index: 3;
                height: 8px;
                width: 85%;
                border-radius: 4px;
                background: linear-gradient(white, $secondary);
                margin-top: 1rem;
                // border-bottom: 5px solid transparent;
                // box-shadow: inset 0 0 10px #311b0b;
                box-shadow: 0 3px 4px #7e7e7e;
            }
        }
    }
}

.page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .card {
        position: relative;
        top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: white;
        border: 1px solid lightgrey;
        border-radius: $border-radius;

        .line {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            width: 100%;
            font-size: .7rem;
            padding-bottom: 1rem;
        }

        .center-line {
            justify-content: space-around;
        }

        .youtube-frame {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            margin: 0;
            padding: 0;

            margin-top: -1.5rem;

            .title {
                font-size: 1rem !important;
            }

            .youtube-embed {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-top: -4rem;

                .video-responsive {
                    overflow: hidden;
                    margin-top: 3rem;
                    width: 98%;
                }

                .video-responsive iframe {
                    width: 100%;
                }

            }
        }

    }

    .title-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem;

        .title {
            font-family: Sans-Serif;
            font-weight: 1000;
            font-size: 1.4rem;
            letter-spacing: 1px;
            padding: .25rem .4rem 0.1rem .4rem;
            margin: 0px;
            text-align: center;
            opacity: .6;
            background-color: #565656;
            color: transparent;
            text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
        }



    }

    .article {
        width: 98%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px ridge #e7e7e7;
        padding-bottom: .3rem;
        margin-bottom: 1rem;

        .article-hd {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: .2rem;

            .qrcode-frame {
                .qrcode-profile {
                    margin: 0px;
                    padding: 0px;

                    svg {
                        border: none;
                    }
                }
            }

            img {
                margin-top: 20px;
                border: 1px outset $secondary;
                border-radius: 4px;
            }
        }

        .article-title {
            font-family: Sans-Serif;
            font-weight: 1000;
            font-size: 1rem;
            letter-spacing: 1px;
            padding: .25rem .8rem 0.3rem .8rem;
            margin: 0px;
            opacity: .6;
            // background-color: #565656;
            color: transparent;


            background: $fourth;
            background: linear-gradient(to right, $fourth 0%, $primary 100%);

            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;


            border: 2px solid $primary;
            border-radius: 16px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

            // text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);

        }

        .article-text {
            font-family: 'Raleway';
        }
    }

    .debug {
        font-family: monospace;
    }

    .qrcode-profile {
        margin: -15px;
    }
}