.dotmenu-frame {
    height: 50px;
    padding: 3px 0 0 5px;
    background: transparent;
}

.dotmenu-box {
    padding-top: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // width: 48px;
    // height: 48px;


    .dotmenu-circle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 41px;
        height: 41px;
        padding-top: -1px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        opacity: 1;
        color: $primary;
        z-index: 1;
        // box-shadow: 0 0 0 2px rgba(255, 255, 255, 1);
        -webkit-transition: background 0.2s, color 0.2s;
        -moz-transition: background 0.2s, color 0.2s;
        transition: background 0.2s, color 0.2s;

        &:hover {
            // box-shadow: 0 0 1px 1px white;
            // opacity: 1;
            // filter: drop-shadow(1px 1px 3px white);
            // border: 1px solid white;
            // transition: all 0.5s ease
            border: 1px solid white;

            /* Smartphones ----------- */
            @media only screen and (max-width: 760px) {
                border: none;
            }
        }

        &:after {
            border: none;
        }

        .menu {
            padding: 3px 0px 0px 0px;

        }

        .cross {
            padding: 3px 1px 0px 4px;
            // background-color: $secondary;
            // color: white;

        }

    }

    .main-cross {
        padding-left: 6px;
    }

    .show {
        position: absolute;
        opacity: 1;
        transform: scaleY(1);
        transition: transform 400ms ease 0ms;
    }

    .hide {
        opacity: 0;
        transform: scaleY(0);
        transition: transform 400ms ease 0ms;
    }
}