.photo-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 7rem;
    height: 7rem;

    .photo-backplan {
        width: 100%;
        height: 100%;
    }
}


.head-main {
    width: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .head-diamond-backplan {
        width: 7rem;
        height: 7rem;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
    }

    .head-title {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: $primary;
        font-size: 1.1rem;
        font-weight: 700;
        font-family: 'Raleway';
        text-align: center;
    }

}