* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  // font-family: 'Raleway', 'Bigelow Rules', sans-serif;
}

*:after,
*:before {
  text-rendering: optimizeLegibility;
}

html {
  font-size: 100%;
  // font-family: 'Raleway', 'Bigelow Rules', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  height: 100%;
}

body,
#root {
  hyphens: none;
  // position: relative;
  // overflow: hidden;
  overscroll-behavior: contain;
  font-family: 'Raleway', 'Bigelow Rules', sans-serif;
  letter-spacing: .05rem;
}


/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none !important;
  color: $third;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    text-decoration: none;
  }
}

sup {
  line-height: 0;
}

p {
  margin: 0;
  padding: 0;
}

/*------------------------------------*\
* RESPONSIVE
\*------------------------------------*/
@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

input,
select,
textarea {
  margin: 0;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  appearance: none;
  cursor: pointer;
  border: none;
  padding: 0;
}

button:focus,
input[type='button']:focus,
input[type='reset']:focus,
input[type='submit']:focus {
  outline: none;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='search'] {
  appearance: textfield;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  appearance: none;
}

textarea {
  overflow: auto;
}

textarea.vertical-only {
  width: 100%;
  resize: vertical;
  min-height: 2rem;
  white-space: pre-line;
  word-wrap: break-word;
}


input[type='text']:focus,
input[type='email']:focus,
input[type='tel']:focus,
input[type='date']:focus,
textarea:focus,
select:focus {
  outline: none;
}

.long-value-input {
  // padding-right: 2.5rem !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.switch-wrapper {
  position: relative;

  >div {
    position: absolute;
    width: 100vw;
  }
}

.hidden {
  display: none !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}