.html-frame {

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 95%;
  overflow: hidden;
  height: fit-content;

  padding-left: 1rem;

  .html-content {
    // overscroll-behavior: contain;
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;
    // border: 2px solid white;
    height: 74vh;

    color: $primary  !important;

    @media only screen and (max-width : 480px) {
      height: 68vh;
    }

    // padding-top: .8rem;
    margin-top: .5rem;
    margin-left: -10px;

    figure img {

      border: 1px solid lightgrey;
      border-radius: $border-radius;
      width: 600px;
      height: auto;
    }

    h1 {
      font-size: 1.2rem;
      text-align: center;
      padding-bottom: 1rem;
      color: $secondary  !important;
    }

    h2 {
      font-size: 1rem;
      padding-bottom: .5rem;
      padding-top: 1rem;
      text-align: center;
      color: $secondary  !important;
    }

    h3 {
      font-size: .85rem;
      padding-top: .5rem;
      padding-bottom: .2rem;
      text-align: center;
      color: $secondary  !important;
    }



    ul,
    li {
      font-size: .8rem;
      color: $primary  !important;
    }

    p {
      font-size: .8rem;
      text-align: justify;
      text-justify: auto;
      color: $primary  !important;
    }

    ol li {
      display: flex;
      display: inline;
      align-self: start;
      padding-right: 0.5em;
      color: $secondary  !important;
    }

    scrollbar-width: 20px;
    scrollbar-color: red blue;

    &::-webkit-scrollbar {
      all: initial !important;
      width: 10px !important;
      background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 20px;
      // border: 2px solid white;
      border-right: 1px solid transparent;

      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.4);
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-button {
      all: initial !important;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-track-piece {
      all: initial !important;
    }

    &::-webkit-scrollbar-corner {
      all: initial !important;
    }

    &::-webkit-resizer {
      all: initial !important;
    }

    ///////////////////////////////////////////////////////////
  }
}