.nonavbar {
    display: none;
    z-index: 0;
}

.tch-navbar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 1200px;
    z-index: 3;
    overflow: hidden;

    .topnav {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: $secondary;

        filter: drop-shadow(0px 1px 3px rgb(0 0 0 / 0.4));

        .left {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center
        }

        .tab-logo {
            position: relative;
            top: 4px;
            width: 64px;
            height: 32px;
            background-color: transparent;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .topnav-frame {
            width: 100%;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            cursor: default;
        }

        .brand-box {
            padding: 0px 12px 0 16px;
            cursor: default;

            margin-top: 6px;
            font-size: 2.5rem;

            .countdown {
                color: $fourth;
                font-size: .7rem;
                text-align: center;
                margin-top: -15px;
                position: relative;
                z-index: 99;

                @supports (-webkit-touch-callout: none) {
                    /* CSS specific to iOS devices */
                    margin-top: -5px;
                }
            }

            .brand-row {
                display: flex;
                flex-direction: row;
                justify-content: center;
                background: transparent;
                color: $primary;

                font-family: 'Bigelow Rules';

                letter-spacing: 2px;

                @supports (-webkit-touch-callout: none) {
                    /* CSS specific to iOS devices */
                    font-weight: bolder;
                    font-size: 3rem;
                }

                @supports not (-webkit-touch-callout: none) {
                    /* CSS for other than iOS devices */
                    font-weight: bold;
                }

                .left-part {
                    height: 50px;
                    text-shadow: 1px 1px 0px $fourth;
                    z-index: 1;
                }

                .center-part {
                    height: 50px;
                    letter-spacing: -2px;
                    color: $primary;
                    z-index: 2;
                    margin-left: -3px;
                    // line-height: 4rem;
                    margin-top: 2px;
                    text-shadow:
                        -1px -1px 0 $third,
                        1px -1px 0 $third,
                        -1px 1px 0 $third,
                        1px 1px 0 $third;

                }

                .right-part {
                    height: 50px;
                    text-shadow: 1px 1px 0px $fourth;
                    z-index: 1;
                }
            }
        }

        // .logo {
        //     background: transparent;
        //     background-size: cover;
        //     width: 48px;
        //     height: 48px;
        //     filter: blur(1px);
        //     position: relative;
        //     top: 5px;
        // }
        .http_address {
            font-family: 'Raleway';
            font-size: .7rem;
        }



        .nav-content-frame {
            z-index: 9;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding: 0 2rem;

            height: 100%;

            .showme {
                display: flex;
            }

            .hideme {
                display: none !important;
            }

            .nav-content-box {
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 100%;

                height: 100%;

                .nav-content {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: 90%;
                    height: 100%;
                    opacity: 0;

                    &.show {
                        transition: opacity .7s ease-in;
                        opacity: 1;
                    }

                    &.hide {
                        // transition: opacity .7s ease-in;
                        display: none !important;
                        opacity: 0;
                    }
                }
            }
        }
    }

    .tabnav {
        position: relative;
        top: -4px;
        // width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        height: 50px;

        #tab {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: transparent;
            cursor: pointer;
            height: 50px;
        }

        .tab-left {
            // width: 10%;
            display: flex;
            background-color: transparent;
        }

        .tab-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: $primary-gradient;

            .tab-center-col {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                height: 50px;

                .tab-frame {
                    min-width: 5rem;
                    height: 32px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    padding: 0px 0px 0px 0px;
                    align-items: center;

                    .tab-icon {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0px 0px 0px 0px;
                        align-items: center;

                        // padding-bottom: 2px;
                        .icon {

                            padding: 0px;

                            &svg {
                                z-index: 1000;
                            }

                        }
                    }

                    .tab-title {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 8rem;
                        width: fit-content;
                        color: $fourth;
                        font-family: 'Raleway';
                        font-size: .9rem;
                        letter-spacing: 1px;
                        // padding-left: 5px;
                    }
                }

                .tab-cursor-frame {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: transparent;

                    .tab-cursor {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        background-color: transparent;

                        .cursor {
                            height: 7px;
                            width: 80%;
                            border-radius: 4px;
                            background-color: white;

                        }

                    }
                }
            }
        }

        .tab-right {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            // width: 80%;
            background-color: transparent;
            // To prevent small buggy white line ... !
            margin-left: -1px;
            z-index: 99;

            & svg {
                transform: rotateY(180deg);
            }
        }



    }

    // .back-link {
    //     position: relative;
    //     top: -50px;
    //     left: 10px;

    //     display: flex;
    //     flex-direction: row;
    //     justify-content: flex-start;
    //     align-items: center;
    //     font-size: .6rem;
    //     width: 100%;

    //     .arrow-back {
    //         padding-bottom: 6px;
    //     }

    //     .txt-back-link {
    //         padding-top: .4rem;
    //         color: black;
    //     }
    // }

}