.inputlink {
    .input-focus {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .focus-frame {
            .input-frame {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .input-box {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding-right: 3px;

                    .input-container {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 2px 0 2px 1px;
                        margin-top: -2px;
                        margin-right: 2px;


                        input {
                            z-index: 99;
                            width: 100%;
                            height: 1.7rem;
                            margin-top: .3rem;
                            padding-top: .1rem;
                            padding-left: .3rem;
                            margin-right: 3px;
                            // background: transparent;
                            border: 1px solid #d0d0d0;
                            border-radius: 8px;
                            // flex: 1 0 auto;
                            font-weight: 600;
                            // color: $third;
                            color: rgb(84, 84, 84);
                            font-size: 1rem;

                            &.not-editing {
                                background-color: rgba(239, 239, 239, 0.3);
                            }

                            &.editing {
                                background-color: white !important;
                            }


                        }

                        .icon-bin {
                            z-index: 99;
                            padding-top: .2rem;
                            margin: .3rem .5rem .1rem 0;
                        }


                        .icon-touched {
                            width: fit-content;
                            height: 18px;
                            margin-left: -4px;
                            margin-right: 2px;

                            >svg {
                                // margin-left: -1px;
                            }
                        }

                        .icon-reset {
                            border-radius: 50%;
                            background: red;
                            width: fit-content;
                            height: 18px;
                            margin-top: 2px;

                            >svg {
                                padding-left: 1px;
                                padding-top: 1px;
                            }
                        }
                    }
                }

            }
        }
    }

    .icon-bin {
        z-index: 99;
        padding-top: .2rem;
        margin: .3rem .5rem .1rem 0;
    }
}