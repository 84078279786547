.auth-frame {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .authentication {
        position: fixed;
        top: 7rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .auth-title-box {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: default;

            .auth-title {
                font-family: Sans-Serif;
                font-weight: 1000;
                font-size: 1.4rem;
                letter-spacing: 1px;
                // text-shadow: 1px 1px 0px grey;
                // border: 2px solid $secondary;
                // border-radius: 4px;
                padding: .25rem .4rem 0.1rem .4rem;
                margin: 0px;
                // background-color: white;
                // color: $primary;
                text-align: center;
                opacity: .6;

                background-color: #565656;

                color: transparent;
                text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
                -webkit-background-clip: text;
                -moz-background-clip: text;
                background-clip: text;




            }
        }
    }

    .firebaseui-idp-button {
        border-radius: $border-radius !important;
    }

    .firebaseui-tos {
        font-family: 'Raleway';
    }

}