// Bootstrap 5.1 compatible
$desktop_width: 1200px;
@import url(https://fonts.googleapis.com/css?family=Bigelow+Rules:400);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,700,900);
$font-family-sans-serif: 'Bigelow Rules',
    'Raleway',
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji"
    -apple-system,
    BlinkMacSystemFont sans-serif,
;

// $themeColor: #ff5950 !important;
$themeColor: #645CBB !important;

// color palette :
// https://colorhunt.co/palette/645cbba084dcbface2ebc7e6

:root {
    --swiper-theme-color: #{$themeColor} !important;
}

$primary-font: 'Raleway';

// $primary: #ff5950;
// $secondary:#f8b1ae;
// $third: #e67c76;
// $fourth: #008000;

$primary: #645CBB;
$secondary: #A084DC;
$third: #BFACE2;
$fourth: #EBC7E6;

$background-color: #f5f5f5;
$pulldown-background-color: $fourth;
$primary-gradient: linear-gradient($secondary, $primary);
$secondary-gradient: linear-gradient($secondary, 'white');
$third-gradient: linear-gradient(-90deg, $secondary, $primary);
$fourth-gradient: linear-gradient(210deg, $primary, $secondary, $primary);


// $primary-gradient: linear-gradient($secondary, $primary);
// $secondary-gradient: linear-gradient($secondary, 'white');
// $third-gradient: linear-gradient(-90deg, $secondary, $primary);

$border-radius: 10px;
$border-radius-lg: 10px;
$border-radius-sm: 10px;

// Toastify customization
.Toastify__toast--error {
    border: 2px solid #fff;
    border-radius: 50px !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    background: #{$primary} !important;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px !important;
}

.Toastify__toast--error::after {
    // content: url('../assets/images/svg/closeToast.svg'); // Your svg Path
    position: absolute;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
}

.Toastify__toast--error::before {
    // content: url("../assets/images/svg/errorToast.svg"); // Your svg Path
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}

.Toastify__toast--success {
    border: 1px solid #3A9EA5 !important;
    border-radius: 50px !important;
    background: #{$primary} !important;
}

.Toastify__toast--success::before {
    // content: url("../assets/images/svg/successToast.svg"); // Your svg Path
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}

.Toastify__toast--success::after {
    // content: url('../assets/images/svg/closeToast.svg'); // Your svg Path
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
}

.Toastify__toast--warning {
    border: 1px solid #fff !important;
    border-radius: 50px !important;
    background: #{$primary} !important;
}

.Toastify__toast--warning::before {
    // content: url("../assets/images/svg/warnToast.svg"); // Your svg Path
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}

.Toastify__toast--warning::after {
    //   content : url('../assets/images/svg/closeToast.svg'); // Your svg Path
    content: 'QQ';
    position: absolute;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
}

.Toastify__toast-body {
    color: #fff;
    font-size: 16px;
    line-height: 1rem;
    // padding: 25px 0 5px 20px;
    width: 100%;
    font-weight: 400;
    margin-left: 25px !important;
    padding-right: 20px !important;
}

.Toastify__toast>button>svg {
    display: none;
}

.swiper-arrow {
    path {
        fill: #fff;

    }
}

.swiper-button-next {
    // background: transparent !important;
    // background-color: linear-gradient($secondary, $primary);
    background-image: url(../images/arrow.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    transform-box: fill-box;
    transform-origin: center;
    transform: rotate(90deg);
    margin-right: -5px;
    top: 24%;

    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));
}

.swiper-button-next::after {
    display: none;
}

.swiper-button-prev {
    background-image: url(../images/arrow.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    transform-box: fill-box;
    transform-origin: center;
    transform: rotate(270deg);
    margin-left: -8px;
    top: 24%;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));

}

.swiper-button-prev::after {
    display: none;
}

// .swiper-wrapper {
//     svg {
//         transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
//         filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));
//     }
// }