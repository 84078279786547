.progress {
    width: 100%;
    max-width: 260px;
    height: 14px;
    background: #e1e4e8;
    border-radius: 28px;
    overflow: hidden;
}

.progress .progress-bar {
    display: block;
    height: 100%;
    // background: linear-gradient(90deg, #ffd33d, #ea4aaa 17%, #b34bff 34%, #01feff 51%, #ffd33d 68%, #ea4aaa 85%, #b34bff);
    background: $primary-gradient;
    background-size: 300% 100%;
    -webkit-animation: progress-animation 2s linear infinite;
    animation: progress-animation 2s linear infinite;
}