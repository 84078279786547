.band-id {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .photo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 96px;
        height: 96px;

        .photo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            height: 96px;


            img {
                aspect-ratio: 1/1;
                // border: 1px solid #212121;
                background-color: transparent;
                border-radius: 50%;
                -moz-border-radius: 50%;
                -webkit-border-radius: 50%;

                // border: 2px outset #d0d0d0;

            }

            .icon-photo-touched {
                position: relative;
                width: fit-content;
                height: 18px;
                // padding-left: 1px;
                // padding: 1px;

                left: -12px;
            }


            .photo-backplan {
                border: 1px solid #212121;
                border-radius: 50%;
                -moz-border-radius: 50%;
                -webkit-border-radius: 50%;
                width: 7rem;
                height: 7rem;
                background-color: transparent;
                background-position: center;
                background-repeat: no-repeat;
            }

        }
    }




    .photo-block {
        padding-left: 5px;
    }

    .id-wrapper {
        // width: 65%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 8px;

        .id-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: left;
            color: white;

            // text-shadow: 1px 1px 0px black;
            // text-shadow: 1px 1px 2px rgb(176, 176, 176), 0 0 1em rgb(121, 94, 94), 0 0 0.2em rgb(255, 255, 255);

            .username {
                position: relative;
                width: 100%;
                margin-bottom: .3rem;
                padding: 0 0 3px .3rem;

                input {
                    width: 100%;
                    font-size: 1.1rem;
                    font-weight: 600;
                    border: 1px solid #d0d0d0;
                    border-radius: 8px;
                    letter-spacing: .1rem;
                    padding-top: 2px;

                    &.visible {
                        background: default;
                    }

                    &.not-visible {
                        background: transparent;
                        color: white;
                        border: none !important;
                    }
                }
            }

            .biography {
                position: relative;
                width: 100%;
                margin-bottom: .3rem;
                padding: 0 0 3px .3rem;

                textarea {
                    width: 100%;
                    font-size: .85rem;
                    font-weight: 400;
                    border: 1px solid #d0d0d0;
                    border-radius: 8px;
                    letter-spacing: .03rem;
                    padding-top: 2px;
                    height: 3.2rem;


                    &.visible {
                        background: default;
                    }

                    &.not-visible {
                        background: transparent;
                        color: white;
                        border: none !important;
                    }
                }
            }
        }
    }
}