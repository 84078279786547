.btn {
    svg {
        transform: rotate(90deg);
    }
}

.badge-edit {
    border-radius: 50%;
    width: 21px;
    height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    &.greyed {
        background: $primary;
    }

    &.normal {
        background: gray;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }


    &.visible {
        visibility: visible;
    }

    &.not-visible {
        visibility: hidden;
    }

    &.badge-photo {
        position: absolute;
        margin-top: -1px;

        >svg {
            margin-top: -2px;
        }
    }

    &.badge-name {
        position: absolute;
        margin-top: -2px;
        margin-left: -12px;
        z-index: 9;
    }

    &.badge-netbio {
        position: absolute;
        margin-top: -2px;
        margin-left: -11px;
        z-index: 9;
    }
}

.bar-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: .6rem;

}

.no-profile {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .no-profile-text {
        width: 70%;
        text-align: center;
        color: lightgrey;
        font-size: 1.4rem;
    }


    svg {
        stroke: lightgrey;
    }
}