.chip-nail-home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    width: 95%;
    max-width: 600px;
}

.home-topic {

    font-size: 1rem;
    font-weight: 700;

    color: $primary;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.article-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .article-line {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        padding: 0.5rem 1rem 0.5rem 1rem;
    }

    .article-image {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
            border-radius: 8px;
            border: 1px solid #d0d0d0;
        }
    }

    .article-title {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .article-text {
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
    }

    .article-admin {
        display: flex;
        flex-direction: row;
        justify-content: left;
        font-size: .8rem;
        font-weight: 700;
    }

    .article-request {
        height: auto;
        overflow: scroll;
    }

    .article-log {
        font-weight: 700;
        font-size: .9rem;
    }

    .input-small {
        width: 3rem;
        margin-left: 1rem;
    }

}


.home-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .card {
        position: relative;
        top: .1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: white;
        border: 1px solid lightgrey;
        border-radius: $border-radius;

        .line {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            width: 100%;
            font-size: .7rem;
            padding-bottom: 1rem;
        }

        .center-line {
            justify-content: space-around;
        }
    }

    .title-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem;

        .title {
            font-family: Sans-Serif;
            font-weight: 1000;
            font-size: 1.4rem;
            letter-spacing: 1px;
            padding: .25rem .4rem 0.1rem .4rem;
            margin: 0px;
            text-align: center;
            opacity: .6;
            background-color: #565656;
            color: transparent;
            text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
        }



    }

    .article {
        width: 98%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px ridge #e7e7e7;
        padding-bottom: .3rem;
        margin-bottom: 1rem;

        .article-hd {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: .2rem;

            .qrcode-frame {
                .qrcode-profile {
                    margin: 0px;
                    padding: 0px;

                    svg {
                        border: none;
                    }
                }
            }

            img {
                margin-top: 20px;
                border: 1px outset $secondary;
                border-radius: 4px;
            }
        }

        .article-title {
            text-align: center;
            font-family: Sans-Serif;
            font-weight: 1000;
            font-size: 1rem;
            letter-spacing: 1px;
            padding: .25rem .8rem 0.3rem .8rem;
            margin: 0px;
            opacity: .6;
            // background-color: #565656;
            color: transparent;

            text-shadow: -1px -1px -1px #d1d1d1;

            background: $fourth;
            background: linear-gradient(to right, $fourth 0%, $primary 100%);

            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;


            border: 1px groove #d1d1d1;
            border-radius: 16px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

            // text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);

        }

        .article-text {
            font-family: 'Raleway';
            margin-top: -1rem;
            background: linear-gradient(to bottom right, white 45%, lightgrey 100%);
            border-radius: 4px;
        }
    }

    .debug {
        font-family: monospace;
    }

    .qrcode-profile {
        margin: -15px;
    }


}