.setonline-frame {
    width: 90%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .setonline-box-title {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1rem;
        font-weight: 600;
        padding-left: .5rem;
        padding-top: .5rem;
        color: #707070;
        letter-spacing: 0rem;

        .exp-time-box {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .exp-time-title {
                width: 50%;
                font-size: .9rem;
            }
        }

    }

    .apple-title {
        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */
            color: $fourth;
            padding: 3px 3px 10px 3px;
            font-weight: bold;
        }

        @supports not (-webkit-touch-callout: none) {
            /* CSS for other than iOS devices */
            display: none;
        }
    }



    .setonline-box {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 3px solid white;
        border-radius: 28px;
        margin: .2rem 0 1rem 0;
        background-color: $third;

        @media screen and (max-height: 600px) {
            display: none;
        }

        .setonline-box-line {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;


            .setonline-arrow {
                padding-top: .4rem;

                svg {
                    transform: rotate(90deg);
                    margin: 0 .5rem 0 .5rem;
                }
            }

        }

    }

    .setonline-exp-box {
        padding-left: 1rem;
        width: 80%;
        $text-color: mix(#000, $primary, 64%);

        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */
            // display: none;
            margin-left: 1rem;
        }

        // font-family: "Inter", sans-serif;
        color: $text-color;
        // font-size: calc(1em + 1.25vw);
        font-size: .9rem;
        letter-spacing: 0rem;
        // background-color: mix(#fff, $primary, 90%);
        background-color: $secondary;

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;


        label {
            display: flex;
            cursor: pointer;
            font-weight: 600;
            position: relative;
            overflow: hidden;
            // margin-bottom: 0.375em;

            /* Accessible outline */
            /* Remove comment to use */

            // &:focus-within {
            //     outline: .125em solid $primary;
            // }


            input {
                position: absolute;
                left: -9999px;

                &:checked+span {
                    background-color: mix(#fff, $primary, 84%);

                    &:before {
                        box-shadow: inset 0 0 0 0.4375em $primary;
                    }
                }
            }

            span {
                display: flex;
                align-items: center;
                padding: 0.375em 0.75em 0.375em 0.375em;

                // border-radius: 99em; // or something higher...
                border-radius: 16px;
                transition: 0.25s ease;

                &:hover {
                    background-color: mix(#fff, $primary, 84%);
                }

                &:before {
                    display: flex;
                    flex-shrink: 0;
                    content: "";
                    background-color: $fourth;
                    width: 1.5em;
                    height: 1.4em;
                    border-radius: 50%;
                    margin-right: 0.375em;
                    transition: 0.25s ease;
                    box-shadow: inset 0 0 0 0.125em $fourth;
                }
            }
        }


        .exp-time-line {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-top: .5rem;
            padding-left: .5rem;


            .exp-time-option-title {
                font-size: .8rem;
                font-weight: bold;
            }

            .exp-time-option-radio {}
        }
    }

    .exp-time-line {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
}

.chip-nail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}