// *::-webkit-scrollbar-track {
//     background: orange;
// }

// *::-webkit-scrollbar-thumb {
//     background-color: blue;
//     border-radius: 20px;
//     border: 3px solid orange;
// }


.netlinks-frame {
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;
    // border: 2px solid white;
    height: calc(100% - 70px);
    padding-top: .8rem;
    padding-left: 10px;

    /////////////////////////////////////////////////////////
    scrollbar-width: 20px;
    // scrollbar-color: red rgb(52, 212, 60);

    &::-webkit-scrollbar {
        all: initial !important;
        width: 10px !important;
        background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 20px;
        // border: 2px solid white;
        border-right: 1px solid transparent;

        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.4);
        // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-button {
        all: initial !important;

    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-track-piece {
        all: initial !important;
    }

    &::-webkit-scrollbar-corner {
        all: initial !important;
    }

    &::-webkit-resizer {
        all: initial !important;
    }

    ///////////////////////////////////////////////////////////


    .netlinks-category {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        height: fit-content;

        .category-name {
            color: white;
            padding-bottom: 5px;
            font-size: 1.2rem;
            letter-spacing: .3rem;
            text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
        }
    }

    .netlinks-box {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-bottom: .3rem;

        &.netlinks-box-profile {
            justify-content: space-evenly;
            width: 75%;
        }

        .netlink {
            position: relative;
            border-radius: 14px;
            border: 3px solid white;
            padding: 5px 3px 0 3px;
            margin-top: 5px;
            margin-bottom: 8px;
            margin-left: 1px;
            margin-right: 1px;
            padding-left: 5px;
            padding-right: 5px;
            opacity: 1;

            &.offline {
                opacity: 0.5;
            }

            .check-badge {
                position: absolute;
                margin-top: -10px;
                margin-left: -10px;
                background-color: green;
                border-radius: 50%;
                border: 1px ridge white;
                padding: 0 2px;

                height: 22px;
            }
        }
    }
}

.editlink-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100% - 70px);

    .editlink-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        flex-wrap: wrap;

        // height: 52.5%;
        flex: 1;
        padding-bottom: .2rem;

        background: transparent;

        .ghost-bar {
            width: 80%;
            position: relative;
            bottom: 0;
            height: 1rem;
            // margin-top: -1rem;
            // background: transparent;
            background: linear-gradient(transparent 0%, gray 1000%);
            border-radius: $border-radius;
        }

        .links {
            overflow: scroll;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            scrollbar-width: none;
            background: transparent;

            // box-shadow: 0 -10px 5px 6px #ccc;
            // border: 5px solid $secondary;
            // -webkit-border-radius: 3px;
            // -moz-border-radius: 3px;
            // -webkit-border-image: linear-gradient(180deg, transparent, $primary) 5 5 5 5 stretch;
            // -moz-border-image: linear-gradient(180deg, transparent, $primary) 5 5 5 5 stretch;

            -webkit-overflow-scrolling: 'touch';

            .netlink-frame {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 0;
            }


            .netlink-empty-frame {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding: 0 0 3rem 0;
                opacity: .5;
            }


        }

        .links::-webkit-scrollbar {
            display: none;
        }

        &.static-btn {
            justify-content: flex-start;
            max-height: 14%;
            margin-bottom: .3rem;
        }

        .netlink-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 1rem 0;

            .netlink-input {
                width: 80%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                flex: 1 0 auto;
                background-color: $secondary;
                border-radius: $border-radius;

                .inputlink {
                    width: 93%;
                }

                .input-add {
                    width: 96%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    // color: $primary;
                    color: white;
                    font-weight: 600;
                    font-size: 1rem;
                }

                .left-picto {
                    margin: .5rem .5rem .2rem .6rem;
                }

                .icon-copy {
                    z-index: 99;
                    padding-top: .2rem;
                    margin: .3rem .5rem .1rem 0;
                }
            }
        }

    }


    .netlink-button {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

    }

}