.response-frame {
    .article-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .article-line {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            padding: 0.5rem 1rem 0.5rem 1rem;

            .button-frame {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
        }

        .article-image {
            width: fit-content;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            img {
                border-radius: 8px;
                border: 1px solid #d0d0d0;
            }
        }

        .article-title {
            font-size: 1.5rem;
            font-weight: 700;
        }

        .article-text {
            text-align: center;
            font-size: 1rem;
            font-weight: 400;
        }

        .article-admin {
            display: flex;
            flex-direction: row;
            justify-content: left;
            font-size: .8rem;
            font-weight: 700;
        }

        .article-request {
            // background-color: red;
            max-height: 18rem;
            overflow: scroll;
        }

        .article-log {
            font-weight: 700;
            font-size: .9rem;
        }

        .input-small {
            width: 3rem;
            margin-left: 1rem;
        }

    }
}

.account-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .7rem;

    table {
        width: 100%;
        max-width: 100%;
        table-layout: fixed;
        overflow-wrap: break-word;

        th,
        td {
            border: 1px solid;
        }

        .col-1 {
            width: 30%;
        }

        .col-2 {
            width: 67%;
        }

        .col-3 {
            width: 3%;
        }
    }
}