// For pullmain and pullhome
.header-space {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.rowbox {
    position: fixed;
    top: 5rem;
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // height: fit-content;

    .colbox {

        display: flex;
        flex-direction: column;


        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */
            justify-content: flex-start;
        }

        @supports not (-webkit-touch-callout: none) {
            /* CSS for other than iOS devices */
            justify-content: space-evenly;
        }




        align-items: center;

        position: fixed;
        top: 5rem;
        bottom: 1.4rem;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        max-width: 400px;

        .title-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.4rem;

            .title-image {

                @media screen and (max-height: 600px) {
                    display: none;
                }

                .main-image {
                    border: 2px solid $third;
                    border-radius: 50%;
                    z-index: 9;

                    img {
                        margin-bottom: -4px;
                        // border: 2px groove $primary;
                        border-radius: 50%;
                    }
                }
            }


            .title-frame {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .title {
                    color: $fourth;
                    font-size: 1.6rem;
                    // margin-left: 10px;
                }


            }
        }

        .buttoncol {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            .button-row {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;

                .button {
                    width: 67%;
                    display: flex;
                    flex-direction: row;
                    justify-content: left;
                    align-items: center;
                    margin-bottom: .95rem;
                    cursor: pointer;
                    height: 42px;

                    .icon {
                        border-bottom-left-radius: $border-radius;
                        border-top-left-radius: $border-radius;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 48px;
                        max-height: 32px;

                        &.rightpad {
                            padding-right: 2px;
                        }

                    }

                    .link {

                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;
                        // padding-left: .5rem;
                        flex-grow: 1;

                        .txtlink,
                        a {
                            color: $fourth  !important;
                            font-size: 1.05rem;
                            letter-spacing: .1rem;
                            // text-shadow: 1px 1px 0px grey;
                        }
                    }
                }
            }

        }

        .row-band {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: .1rem;

            .line-band {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .policy-link {
                    color: $fourth;
                    text-decoration: none;
                    font-weight: 400;
                    font-size: .85rem;
                    cursor: pointer;

                    a {
                        color: ghostwhite;
                        text-decoration: none !important;
                    }
                }
            }
        }



    }
}