.select-wrapper {
    width: 8rem;
    height: 1rem;
    position: relative;
    display: flex;
    cursor: pointer;
    margin: 0;
    padding: 0;

    border-radius: 12px;

    //  transition: ${({ shadow }) => (shadow ? '0.3s cubic-bezier(0.25, 0.8, 0.25, 1)' : '')};
    // box-shadow: ${({ shadow }) => (shadow ? '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)' : '')};
    .select-lang {
        &.active {
            svg {
                transform: rotate(-180deg);
            }
        }
    }

    .select-lang {
        background: $primary;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        margin: 0;
        padding: 0 0 0 .3rem;



        .selected-frame {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: white;
            color: $primary;
            padding: 0;
            margin-left: .3rem;
            width: 100%;
            height: 100%;

            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .selected-value {
            width: fit-content;
            height: fit-content;
        }

        svg {
            transition: all 0.1s ease-in-out;
            //   transform: ${({ active }) => (active ? 'rotate(-90deg)' : 'rotate(90deg)')};
            fill: #fff;
            width: 16px;
            height: 16px;
        }

    }

    .options-wrapper {
        position: absolute;
        bottom: 1.1rem;
        left: 1.35rem;
        // top: 0px;
        // left: -12px;
        display: box;
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
        width: 4rem;
        height: auto;
        max-height: 170px;
        border-top-right-radius: $border-radius;
        border-top-left-radius: 2px;
        border-bottom-right-radius: $border-radius;
        overflow: auto;
        z-index: 12;
        padding-top: .3rem;
        display: none;

        &.active {
            display: block;
        }

        .button-lang {
            z-index: 12 !important;
            height: 1.3rem;
            width: 95%;
            //   background-color: ${({ selected }) => (selected ? 'var(--tuto-icons)' : '#ffffff')};
            font-size: .8rem;
            justify-content: flex-start;
            padding-left: 6px;
            padding-right: 6px;
            text-align: left;
            color: #212121;

            &.selected {
                color: $primary;
            }

            //   color: ${({ selected }) => (selected ? '#ffffff' : '#000')};
            :hover {
                background-color: $secondary;
                color: black;
            }
        }
    }


}